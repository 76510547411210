<template>
    <div class="cs-block cs-image-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
            <div class="container">
                <div class="header-content">
                    <h2 v-if="data.title" class="cs-title">
                        <ColorSplit :data="data.title"/>
                    </h2>
                    <h3 v-if="data.subTitle" class="cs-sub-title">{{data.subTitle}}</h3>
                </div>
                <span v-if="data.text" class="cs-text" v-html="data.text"></span>
                <div class="image">
                    <img :src="data.image">
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: 'ImageBlock',
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
    },
    data() {
        return {
        }
    },
    computed: {
        ...computed('ImageBlock'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    },
	methods: {},
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/_mixins.scss';

.cs-block.cs-image-block {
    &.cs-style- {
        &color {
            @include cs-block-color;
            .cs-block-base {
                .cs-sub-title {
                    color: $sub_title_color;
                }
                .cs-title, .cs-text {
                    color: $text_color_over_theme_color;
                }
                .cs-text {
                    ::v-deep {
                        * {
                            color: $text_color_over_theme_color !important;
                        }
                    }
                }
            }
        }

        &light {
            @include cs-block-light;
            .cs-sub-title {
                color: $sub_title_color_light;
            }
        }

        &dark {
            @include cs-block-dark;
            .cs-block-base{
                h3{
                    color: $sub_title_color_dark;
                }
            }
        }
    }
    .header-content {
        text-align: center;
        margin-bottom: $header_margin;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .cs-text {
        text-align:center;
        ::v-deep {
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
    .image{
        position: relative;
        margin-top: 30px;
        img{
            object-fit: cover;
            width: 100%;
            height: 500px;
            border-radius: 40px;
            z-index: 10;
            position: relative;
            @media(max-width: 600px) {
                height: 300px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 104%;
            width: 102%;
            border-radius: 40px;
            background: #ffffff57;
            filter: blur(1px);
            border: 1px solid white;
            @media (max-width: 991px) {
                width: 103%;
            }
        }
    }
}
</style>
